import React, {useState, useEffect} from 'react';
import Helmet from 'react-helmet';
import Layout from '../components/Layout';
import FadeIn from "../components/FadeIn"
import SlideLeftOnAppear from "../components/SlideLeftOnAppear"
import SlideRightOnAppear from "../components/SlideRightOnAppear"

import salleRestaurant from '../assets/images/salle-restaurant.jpg';
import assietteRestaurant from '../assets/images/assiette-restaurant.jpg'

const IndexPage = () => {
  const [loaded, setloaded] = useState(false)

  useEffect(() => {
    if(!loaded && typeof window !== undefined)
    {
      setloaded(true)
    }
    return () => {
      
    }
  }, [])

  return (
    <>
    <Helmet
          title="Restaurant - Le relais de l'avance"
          meta={[
            { name: 'description', content: "Le relais de l'avance est un Bar - Restaurant - Traiteur pour mariage et tous événements situé à  Saint-Étienne-le-Laus dans les Hautes Alpes 05" },
            { name: 'og:title', content: "Relais de l'avance Restaurant traiteur mariage Hautes Alpes 05"},
            { name: 'og:description', content: "Le relais de l'avance est un Bar - restaurant - traiteur pour mariage avec le concept resto'roul situé à  Saint-Étienne-le-Laus dans les Hautes Alpes 05"},
            { name: 'og:type', content: "website"}
          ]}
        >
      </Helmet>
    <Layout activeLink="restaurant">
      <section className="page-section about-heading">
        <div className="container">
          <FadeIn loaded={loaded}>
            <img
              className="img-fluid rounded about-heading-img mb-3 mb-lg-0"
              src={salleRestaurant}
              alt="salle restaurant"
            />
          </FadeIn>
          <div className="about-heading-content">
            <div className="row">
              <div className="col-xl-9 col-lg-10 mx-auto">
                <SlideLeftOnAppear loaded={loaded}>
                  <div className="bg-faded rounded p-5">
                    <h1 className="section-heading mb-4 section-heading-lower">
                      Le relais de l’avance - Restaurant
                    </h1>
                    <p className="section-heading mb-4 section-heading-upper">
                      Mais qui sommes-nous ?
                    </p>
                    <article>
                      <p><strong>Thierry</strong>, chef de cuisine, formé par l’apprentissage dès ses quinze ans dans un restaurant gastronomique,
                      puis ayant travaillé dans de bonnes maisons et différents secteur de la restauration, comme la brasserie, le traiteur, le traditionnel et les villages club (d’où une grande expérience des buffets).</p>
                      <p><strong>Véro</strong>, formée également en restauration et plus spécialement au service et l’hôtellerie, épouse infatigable au caractère bien trempé.</p>
                      
                    </article>
                  </div>
                </SlideLeftOnAppear>
              </div>
            </div>
          </div>
          
        </div>
      </section>
      <section className="page-section about-heading" style={{marginBottom: '10rem'}}>
        <div className="container">
          <FadeIn loaded={loaded}>
            <img
              className="img-fluid rounded about-heading-img mb-3 mb-lg-0"
              src={assietteRestaurant}
              alt="assiette restaurant"
            />
          </FadeIn>
          <div className="about-heading-content">
            <div className="row">
              <div className="col-xl-9 col-lg-10 mx-auto">
                <SlideRightOnAppear loaded={loaded}>
                  <div className="bg-faded rounded p-5">
                    <h2 className="section-heading mb-4 section-heading-upper">
                      Restaurant Haut Alpin pour une cuisine traditionnelle mais raffinée
                    </h2>
                    <article>
                      <p>
                      Notre cuisine est traditionnelle, bistronomique, familiale, goûteuse, tantôt originale, tantôt classique... <br/>
                      Bref, elle reflète parfaitement l’expérience et le caractère de son chef qui se fait fort de la proposer toujours à des prix très 
                      compétitifs. <br/>
                      Nos soirées gourmandes à thème sont toujours très appréciées (à découvrir sur la page Facebook «	relais de 
                      l’avance »
                      </p>
                      
                    </article>
                  </div>
                </SlideRightOnAppear>
              </div>
            </div>
          </div>
          
        </div>
      </section>

      <section className="page-section about-heading">
        <div className="container">
        {/*  <img
            className="img-fluid rounded about-heading-img mb-3 mb-lg-0"
            src={menuRestaurant}
            alt="assiette restaurant"
          /> */}
          <div className="about-heading-content">
            <div className="row">
              <div className="col-xl-9 col-lg-10 mx-auto">
                <SlideLeftOnAppear loaded={loaded}>
                  <div className="bg-faded rounded p-5">
                    <h2 className="section-heading mb-4 section-heading-upper">
                      Restaurant de l'avance : Notre carte
                    </h2>
                    <article>
                      <p>Nous vous proposons tous les jours :</p>
                        <ul>
                          <li>Un menu à <strong>14€</strong> (<strong>16€</strong> le week-end) avec un choix de trois entrées et trois plats</li>
                          <li>Un menu terroir à <strong>21€</strong></li>
                          <li>Une carte saisonnière de 5 plats</li>
                          <li>Un choix de 10 burgers maisons</li>
                        </ul>
                        <p>Toutes ces propositions se font au gré de l’humeur du chef et du marché.</p>
                    </article>
                  </div>
                </SlideLeftOnAppear>
              </div>
            </div>
          </div>
          
        </div>
      </section>
    </Layout>
    </>
)}

export default IndexPage;
